.contact-blocks {
	display: flex;
	flex-direction: column;

	margin-top: 1em;
	list-style: none;
	font-size: 125%;
}

.contact-block {
	margin-bottom: .25em;
}

.contact-block a {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.contact-block img {
	margin-right: 1em;

	background: var(--theme-7);

	padding: 0.5em;
	box-shadow: .125em .125em .125em #444;
	border-radius: 0.3em;
}

.contact-block a {
	color: var(--theme-1);
	text-decoration: none;
}
