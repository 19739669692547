@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
:root {
	--theme-0: #1a1a1a;
	--theme-1: #0f2459;
	--theme-2: #37154a;
	--theme-3: #75003c;
	--theme-4: #c43911;
	--theme-5: #f78f00;
	--theme-6: #e6e6e6;
	--theme-7: #ffffff;
}

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont,
		"Source Sans Pro", "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-y: scroll;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h2 {
	font-size: 24pt;
}

h2, h3, p {
	margin: 0;
	margin-bottom: 0.25em;
}

p {
	margin-bottom: 0.75em;
}

p:last-child {
	margin-bottom: 0;
}

html, body, #root {
	height: 100%;
	background-color: #0f2459;
	background-color: var(--theme-1);
	color: #e6e6e6;
	color: var(--theme-6);
}

.App {
	display: -webkit-flex;
	display: flex;
	width: 1080px;
	max-width: 100%;
	margin: auto;
	background-color: #e6e6e6;
	background-color: var(--theme-6);
	color: #1a1a1a;
	color: var(--theme-0);
	min-height: 100%;
}

.App>*{
	padding: 1em 0;
}

.App>*>*{
	margin: .5em;
}

.mobile-menu-button {
	display: none;
}

.sidebar {
	text-align: center;
	min-width: 270px;
	max-width: 270px;
	margin-left: 1.5em;
	background-color: #37154a;
	background-color: var(--theme-2);
	color: #e6e6e6;
	color: var(--theme-6);
	position: fixed;
	min-height: 100%;

	display: -webkit-flex;

	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.logo {
	background-color: #e6e6e6;
	background-color: var(--theme-6);
	border-radius: 50%;
	width: 200px;
	height: 200px;
}

.sidebar h1 {
	font-family: sans-serif;
	font-weight: bold;
	padding-bottom: 0.5em;
	border-bottom: 1px solid #ffffff;
	border-bottom: 1px solid var(--theme-7);
	font-size: 32pt;
	margin-bottom: 0.25em;
}

.sidebar nav {
	margin: 0;
	padding-top: 1.5em;
}

nav>div>a {
	display: block;
	color: inherit;
	font-size: 125%;
	line-height: 2em;
	cursor: pointer;
	text-decoration: none;
}

nav a:hover {
	text-decoration: none;
	font-weight: bold;
	color: #ffffff;
	color: var(--theme-7);
}

nav>.selected::before,
nav>.selected::after {
	width: 1em;
	content: '.';
	position: absolute;
	top: -.4em;
	display: block;

	background-color: #000;

	-webkit-transform: skew(0deg, -37.5deg);

	        transform: skew(0deg, -37.5deg);
	z-index: 0;
}

nav>.selected::after {
	right: 0;
	-webkit-transform: skew(0deg, 37.5deg);
	        transform: skew(0deg, 37.5deg);
}

nav>.selected{
	position: relative;
	width: 119%;
	margin-left: -9.44%;
}

nav>.selected a{
	position: relative;

	font-weight: bold;
	color: #ffffff;
	color: var(--theme-7);
	background-color: #75003c;
	background-color: var(--theme-3);
	z-index: 1;
}
.sidebarMain {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
}

.sidebarMain .download-link {
	margin-top: 3em;
}

.footer {
	margin: 4em auto 2.5em auto;
	-webkit-flex-grow: 0;
	        flex-grow: 0;
	width: 100%;
	text-align: center;
	background-color: #e6e6e6;
	background-color: var(--theme-6);
	color: #1a1a1a;
	color: var(--theme-0);
	padding: 0.5em 0;
}


#PageContainer {
	margin-left: 300px;
	padding: 1em;
	width: 100%;
}

.page>.content {
	clear: both;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding-top: 1em;
	font-size: 14pt;
}

.content>.intro {
	margin-bottom: 1em;
}

.content .logo {
	display: none;
}

.banner {
	background-color: #75003c;
	background-color: var(--theme-3);
	color: #e6e6e6;
	color: var(--theme-6);
	font-weight: bold;
	padding: 0.25em 1em 0.25em 0.5em;
	float: right;
	margin-right: -1em;
	box-shadow: .125em .125em .125em #222;
}

.banner-left {
	float: left;
	margin-left: -1em;
	box-shadow: -.125em .125em .125em #222;
}

.page h2>.icon {
	width: 32px;
	height: 32px;
	background: #e6e6e6;
	background: var(--theme-6);
	border-radius: 50%;
	margin-right: 0.5em;
	vertical-align: sub;
}

.info-card {
	border: 2px solid #f78f00;
	border: 2px solid var(--theme-5);
	margin: auto;
	margin-bottom: 1em;
	background: #ffffff;
	background: var(--theme-7);
	width: 100%;
}

.info-card>.title{
	font-size: 110%;
	font-weight: bold;
	color: #ffffff;
	color: var(--theme-7);
	background-color: #f78f00;
	background-color: var(--theme-5);
	text-align: center;
	padding: 0.25em;
	text-shadow: 1px 1px 4px #000000;
}

.info-card>.contents{
	padding: 0.5em;
}

.info-card>.contents:first-child{
	padding-top: 0;
}


.info-card .details {
}

.info-card .subheading {
	font-weight: bold;
	margin-bottom: 0.5em;
}

ul {
	margin: 0;
}

.split {
	-webkit-columns: 2;
	        columns: 2;
}

li {
	page-break-inside: avoid;
	-webkit-column-break-inside: avoid;
	        break-inside: avoid;
}

.hang {
	padding-left: 2em;
}


.hang{
	text-indent: -2em;
}



.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	transition: -webkit-transform box-shadow;
	transition: transform box-shadow;
	transition: transform box-shadow, -webkit-transform box-shadow;
	transition-duration: .5s;
	transition-delay: 0.25s;
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner,
.flip-card.flipped .flip-card-inner {
	-webkit-transform: rotateY(180deg);
	        transform: rotateY(180deg);
	transition-delay: 0s;
}

.flip-card-front, .flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

.flip-card-back {
	-webkit-transform: rotateY(180deg);
	        transform: rotateY(180deg);
}

@media only screen and (max-width: 768px) {
	.App {
		width: 100%;
	}

	#PageContainer {
		margin-left: auto;
		padding-bottom: 4em;
	}

	.sidebar {
		display: none;
		min-width: 85%;
		max-width: 85%;
		margin: 0 7.5%;
		position: static;
		position: initial;
	}

	.mobile-menu-button {
		display: block;
		position: fixed;
		bottom: 1em;
		padding-left: 1.5em;
		left: -1em;
		cursor: pointer;
		z-index: 99;
	}

	.mobile-nav-show {
		display: -webkit-flex;
		display: flex;
	}

	.split {
		-webkit-columns: 1;
		        columns: 1;
	}

	.content .logo {
		display: block;
		margin: auto;
	}
}

.hide {
	display: none;
}

summary {
	cursor: pointer;
}

details>summary>* {
	display: inline-block;
}

.contact-blocks {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;

	margin-top: 1em;
	list-style: none;
	font-size: 125%;
}

.contact-block {
	margin-bottom: .25em;
}

.contact-block a {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
}

.contact-block img {
	margin-right: 1em;

	background: var(--theme-7);

	padding: 0.5em;
	box-shadow: .125em .125em .125em #444;
	border-radius: 0.3em;
}

.contact-block a {
	color: var(--theme-1);
	text-decoration: none;
}

.degree-blocks {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
}

.degree-block {
	margin-bottom: 1em;
	text-shadow: 1px 1px 4px #000000;
}

.degree-block .flip-card-inner {
	width: 8em;
	height: 8em;

	text-align: center;
	color: var(--theme-7);
	font-size: 14pt;

	box-shadow: .25em .25em .25em #444;
}

.degree-block:hover .flip-card-inner {
	box-shadow: -0.25em 0.25em .25em #444;
}



.degree-block>*>* {
	background: var(--theme-5);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}


.degree-block.pending>*>* {
	background-color: var(--theme-4);
}


.degree-block.pending .year::after {
	content: '*';
}


.degree-block>*>*>*{
	-webkit-flex-basis: 100%;
	        flex-basis: 100%;

	display: -webkit-flex;

	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;

	padding-bottom: .25em;
}

.degree-block .type {
	font-weight: bold;
	font-size: 52pt;
}

.degree-block .field,
.degree-block .year {
	font-size: 16pt
}

.degree-block .institution {
	font-weight: bold;
	font-size: 22pt;
}


.info-card img {
	float: right;
}

.skills {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	margin-bottom: 1em;
}

.skill {
	margin: 0.125em;
}

.skill .flip-card-inner {
	border-radius: .5em;
	width: 6em;
	height: 5.5em;

	text-align: center;
	font-size: 12pt;

	box-shadow: .125em .125em .125em #222;
}

.skill:hover .flip-card-inner {
	box-shadow: -.125em .125em .125em #222;
}

.skill>*>* {
	height: 100%;
	border-radius: .5em;

	background: var(--theme-7);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
}
.skill .flip-card-inner>*>* {
	padding: 0.25em;
}

.skill img {
	margin: 0 .75em;
	font-size: 9pt;
}

.progressBar {
	width: 70px;
	height: 14px;
	position: relative;
	margin: 0.25em auto;
	background-color: #d7d7d7;
	-webkit-mask: url(/static/media/progress-mask.a315b824.svg);
	        mask: url(/static/media/progress-mask.a315b824.svg);
}

.progressBar .fill {
	background-color: var(--theme-5);
	width: 100%;
	height: 100%;
}

.skill-alt-view .skill-rating{
	font-size: 80%;
	font-style: italic;
}

.info-card .icon {
	text-align: center;
	float: right;
	font-size: 11pt;
	line-height: 0.75em;
	text-decoration: none;
}
